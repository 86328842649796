import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div>
                <section className="colorlib-about" data-section="about">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                                    <div className="col-md-12">
                                        <div className="about-desc">
                                            <span className="heading-meta">About Me</span>
                                            <h2 className="colorlib-heading">Who Am I?</h2>
                                            <p>I am a software developer based on New Orleans, Louisiana. I specialize in React apps, Angular, CSS, JAVA, .NET, and C# that work across all platforms and browsers. </p>
                                            <p>I am a recent graduate in Computer Science and excited about working towards becoming a React Senior.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="colorlib-about">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">What I do?</span>
                                <h2 className="colorlib-heading"> </h2>
                            </div>
                        </div>
                        <div className="row row-pt-md">
                            <div className="col-md-4 text-center animate-box">
                                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                                    <div className="desc">
                                        <h3>Web and Mobile development </h3>
                                        <p>I have an experience building mobile and web applications using React Native, Angular, React JS, HTML, and CSS.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-center animate-box">
                                <div className="services color-3">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                                    <div className="desc">
                                        <h3>Data Structures & Algorithms</h3>
                                        <p>I have strong fundamental concept of Data Structure and Algorithm.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-center animate-box">
                                <div className="services color-5">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                                    <div className="desc">
                                        <h3>Dev Ops</h3>
                                        <p>I am learning DevOps to bring agile process in development to the deployment process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
