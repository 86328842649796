import React, { Component } from 'react'

export default class Timeline extends Component {
    render() {
        return (
            <div>
                <section className="colorlib-experience" data-section="timeline">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">What I Did?</span>
                                <h2 className="colorlib-heading animate-box">My TimeLine</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="timeline-centered">
                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-3">
                                                <i className="icon-device-laptop" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>IT Analyst II at Utah State Board of Education <span>June 2021 - present</span></h2>
                                                <p> I am currently working as an IT Analyst II at USBE. My principal duties are microservices development including domain modeling,
                                                    utilize software development best practices to create software solutions, implement secure coding standards and practices,
                                                    develop web applications using technologies such as .NET, C#, HTML, CSS, JSON, JavaScript, React, collaborate and communicate effectively in an agile environment, and contribute to the team in analysis, design, and testing.</p>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-3">
                                                <i className="icon-device-laptop" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Internship at Intralox LLC <span>Feb 2020 - Dec 2020</span></h2>
                                                <p>I worked as a software developer intern at Intralox. My major part of the work was to review, debug, and write codes for JAVA and .NET applications related to backend processing systems. </p>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-4">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Undergraduation from SELU <span>2017-2020</span></h2>
                                                <p>I completed my undergraduate from Southeastern Louisiana University. I have taken many courses like Data Structure and Algorithm, Software Engineering, Computer Networking Security, Operating Systems and many more.
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-5">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Primary and Higher Education <span>2003-2015</span></h2>
                                                <p>I have completed my higher secondary education with major subjects such as Physics,Chemistry & Maths as a rank holder.</p>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-none">
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
