import React,{Component} from 'react';


class LandingPage extends Component{
    render(){
        return(
            <div>
                <section id="colorlib-hero" className="js-fullheight" data-section="home">
                    <div className="flexslider js-fullheight">
                        <ul className="slides">
                            <li style={{backgroundImage: 'url(images/FullPhotoGrad.jpg)' }}>
                                <div className="overlay" />
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                                            <div className="slider-text-inner js-fullheight">
                                                <div className="desc">
                                                    <h1>Hi! <br />I'm Prakash</h1>
                                                </div>
                                                <div>
                                                    <p><a className="btn btn-primary btn-learn" href="https://docs.google.com/document/d/13ynGiflsY5mxVnwuo1_K_YNQroTjtSJI-jf_u9CnZNc/edit?usp=sharing" target="_blank" rel="noopener noreferrer">View CV<i className="icon-document-text" /></a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>

        )
    }
}


export default LandingPage;
