import React, { Component } from 'react'
import Images from "../images";
import Avatar from 'react-avatar';

export default class Sidebar extends Component {
    render() {
        return (
            <div>
                <div>
                    <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>
                    <aside id="colorlib-aside" className="border js-fullheight">
                        <div className="text-center">
                            <div className="author-img" style={{backgroundImage: 'url(images/avatphoto.jpeg)' }} />

                            <h1 id="colorlib-logo"><a href="index.html">Prakash Timalsina</a></h1>
                            <span className="email"><i className="icon-mail"></i> timalsinaprakash4@gmail.com</span>
                        </div>
                        <nav id="colorlib-main-menu" role="navigation" className="navbar">
                            <div id="navbar" className="collapse">
                                <ul>
                                    <li className="active"><a href="#home" data-nav-section="home">Introduction</a></li>
                                    <li><a href="#about" data-nav-section="about">About</a></li>

                                    <li><a href="#timeline" data-nav-section="timeline">Timeline</a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className="colorlib-footer">
                            <p><small>
                                <a href="https://www.linkedin.com/in/prakash-timalsina-5a0079184/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                            </small></p>
                        </div>

                    </aside>
                </div>
            </div>
        )
    }
}
