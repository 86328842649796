const images ={
    contact:{
        avatar: require('./images/prakash.png')
    },
    landing:{
        background: require('./images/background.png')
    },
    linkedIn:{
        cover: require('./images/linkedIn.png')
    }

    //Just to use it for future.... the background images are from public/images
}

export default images;
